import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { ButtonText } from '@powdr/constants';

export const GroupsTeamsV3Form = ({
  reCaptchaPublicKey,
  processForm,
  isUserVerified,
  setUserVerified,
  handleCaptchaExpiry,
  buttonText,
}) => {
  const captchaRef = useRef();

  return (
    <form id="groups-teams-v3-form" onSubmit={(e) => processForm(e, 'buk_9qQABV26pVg1HuOekb5blQye', null, captchaRef)}>
      <h3>Name *</h3>
      <input className="form-control" id="name" name="name" placeholder="Name" type="text" required="" />
      <h3>Phone # *</h3>
      <input className="form-control" id="phone" name="phone" placeholder="Phone #" type="tel" required="" />
      <h3>Email Address *</h3>
      <input className="form-control" id="email" name="email" placeholder="Email address" type="email" required="" />
      <h3>Message *</h3>
      <textarea id="message" name="message" rows="9" cols="33" required="" />
      <p>Let us know a bit about you and the program you’d like to attend and we’ll take it from there! Expect a reply within one business day.</p>
      <h3>Program Interest</h3>
      <ul className="l-group">
        <li className="l-item">
          <input className="checkbox" id="parkour" name="parkour" type="checkbox" value="parkour" />
          <label htmlFor="parkour">Parkour</label>
        </li>
        <li className="l-item">
          <input className="checkbox" id="scooter" name="scooter" type="checkbox" value="scooter" />
          <label htmlFor="scooter">Scooter</label>
        </li>
        <li className="l-item">
          <input className="checkbox" id="skate" name="skate" type="checkbox" value="skate" />
          <label htmlFor="skate">Skate</label>
        </li>
        <li className="l-item">
          <input className="checkbox" id="bmxracing" name="bmxracing" type="checkbox" value="bmxracing" />
          <label htmlFor="bmxracing">BMX Racing</label>
        </li>
        <li className="l-item">
          <input className="checkbox" id="bmxfreestyle" name="bmxfreestyle" type="checkbox" value="bmxfreestyle" />
          <label htmlFor="bmxfreestyle">BMX Freestyle</label>
        </li>
        <li className="l-item">
          <input className="checkbox" id="rollerSkate" name="rollerSkate" type="checkbox" value="rollerSkate" />
          <label htmlFor="rollerSkate">Roller Skate</label>
        </li>
        <li className="l-item">
          <input className="checkbox" id="multiSport" name="multiSport" type="checkbox" value="multiSport" />
          <label htmlFor="multiSport">Multisport</label>
        </li>
        <li className="l-item">
          <input className="checkbox" id="other" name="other" type="checkbox" value="other" />
          <label htmlFor="other">Other</label>
        </li>
      </ul>

      <ReCAPTCHA
        ref={captchaRef}
        className="recaptcha"
        sitekey={reCaptchaPublicKey}
        theme="light"
        size="normal"
        onChange={() => setUserVerified(true)}
        onExpired={() => handleCaptchaExpiry()}
      />

      <button type="submit" value="Submit" disabled={!isUserVerified}>{buttonText || ButtonText.SUBMIT}</button>
    </form>
  );
};

GroupsTeamsV3Form.propTypes = {
  reCaptchaPublicKey: PropTypes.string.isRequired,
  processForm: PropTypes.func.isRequired,
  setUserVerified: PropTypes.func.isRequired,
  isUserVerified: PropTypes.bool.isRequired,
  handleCaptchaExpiry: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

GroupsTeamsV3Form.defaultProps = {
  buttonText: null,
};
