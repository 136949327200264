/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { Checkbox } from '@powdr/components';
import { ButtonText } from '@powdr/constants';

export const SignupSuccessForm = ({
  reCaptchaPublicKey,
  // processForm,
  isUserVerified,
  setUserVerified,
  handleCaptchaExpiry,
  buttonText,
}) => {
  const captchaRef = useRef();
  return (
    <form
      id="signup-success"
      action="https://www.pages03.net/powdr/WoodwardPAWebform2021/WoodwardPAWebform2021"
      method="post"
      pageid="14827229"
      parentpageid="14827227"
      siteid="540738"
    >
      <input name="formSourceName" type="hidden" value="StandardForm" />
      <input name="sp_exp" type="hidden" value="yes" />

      <h3>Email address *</h3>
      <input
        name="email"
        id="control_EMAIL"
        className="form-control"
        type="email"
        required
      />

      <h3>First Name</h3>
      <input
        name="First Name"
        id="control_COLUMN3"
        className="form-control"
        type="text"
      />

      <h3>Last Name</h3>
      <input
        name="Last Name"
        id="control_COLUMN4"
        className="form-control"
        type="text"
      />

      <h3>State</h3>
      <select className="form-control" id="control_COLUMN7" name="state">
        <option value="">- select a state -</option>
        <option value="AL">Alabama</option>
        <option value="AK">Alaska</option>
        <option value="AB">Alberta</option>
        <option value="AZ">Arizona</option>
        <option value="AR">Arkansas</option>
        <option value="BC">British Columbia</option>
        <option value="CA">California</option>
        <option value="CO">Colorado</option>
        <option value="CT">Connecticut</option>
        <option value="DE">Delaware</option>
        <option value="DC">District of Columbia</option>
        <option value="FL">Florida</option>
        <option value="GA">Georgia</option>
        <option value="HI">Hawaii</option>
        <option value="ID">Idaho</option>
        <option value="IL">Illinois</option>
        <option value="IN">Indiana</option>
        <option value="IA">Iowa</option>
        <option value="KS">Kansas</option>
        <option value="KY">Kentucky</option>
        <option value="LA">Louisiana</option>
        <option value="ME">Maine</option>
        <option value="MB">Manitoba</option>
        <option value="MD">Maryland</option>
        <option value="MA">Massachusetts</option>
        <option value="MI">Michigan</option>
        <option value="MN">Minnesota</option>
        <option value="MS">Mississippi</option>
        <option value="MO">Missouri</option>
        <option value="MT">Montana</option>
        <option value="NE">Nebraska</option>
        <option value="NV">Nevada</option>
        <option value="NB">New Brunswick</option>
        <option value="NH">New Hampshire</option>
        <option value="NJ">New Jersey</option>
        <option value="NM">New Mexico</option>
        <option value="NY">New York</option>
        <option value="NL">Newfoundland and Labrador</option>
        <option value="NC">North Carolina</option>
        <option value="ND">North Dakota</option>
        <option value="NT">Northwest Territories</option>
        <option value="NS">Nova Scotia</option>
        <option value="NU">Nunavut</option>
        <option value="OH">Ohio</option>
        <option value="OK">Oklahoma</option>
        <option value="ON">Ontario</option>
        <option value="OR">Oregon</option>
        <option value="PA">Pennsylvania</option>
        <option value="PE">Prince Edward Island</option>
        <option value="QC">Quebec</option>
        <option value="RI">Rhode Island</option>
        <option value="SK">Saskatchewan</option>
        <option value="SC">South Carolina</option>
        <option value="SD">South Dakota</option>
        <option value="TN">Tennessee</option>
        <option value="TX">Texas</option>
        <option value="UT">Utah</option>
        <option value="VT">Vermont</option>
        <option value="VA">Virginia</option>
        <option value="WA">Washington</option>
        <option value="WV">West Virginia</option>
        <option value="WI">Wisconsin</option>
        <option value="WY">Wyoming</option>
        <option value="YT">Yukon</option>
      </select>

      <h3>Sports Interest (Select all that apply):</h3>
      <Checkbox
        isIgnoreLabelFor
        className="formCheckbox"
        roundedToggle
        name="SUBSCRIPTION_GROUP"
        value="14050734"
        label="BMX Freestyle"
      />
      <Checkbox
        isIgnoreLabelFor
        className="formCheckbox"
        roundedToggle
        name="SUBSCRIPTION_GROUP"
        value="14050742"
        label="Parkour"
      />
      <Checkbox
        isIgnoreLabelFor
        className="formCheckbox"
        roundedToggle
        name="SUBSCRIPTION_GROUP"
        value="15493697"
        label="Roller Skate"
      />
      <Checkbox
        isIgnoreLabelFor
        className="formCheckbox"
        roundedToggle
        name="SUBSCRIPTION_GROUP"
        value="14050743"
        label="Scooter"
      />
      <Checkbox
        isIgnoreLabelFor
        className="formCheckbox"
        roundedToggle
        name="SUBSCRIPTION_GROUP"
        value="14050747"
        label="Skateboarding"
      />

      <ReCAPTCHA
        ref={captchaRef}
        className="recaptcha"
        sitekey={reCaptchaPublicKey}
        theme="light"
        size="normal"
        onChange={() => setUserVerified(true)}
        onExpired={() => handleCaptchaExpiry()}
      />

      <button type="submit" value="Submit" disabled={!isUserVerified}>{buttonText || ButtonText.SUBMIT}</button>
    </form>
  );
};

SignupSuccessForm.propTypes = {
  reCaptchaPublicKey: PropTypes.string.isRequired,
  // processForm: PropTypes.func.isRequired,
  setUserVerified: PropTypes.func.isRequired,
  isUserVerified: PropTypes.bool.isRequired,
  handleCaptchaExpiry: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

SignupSuccessForm.defaultProps = {
  buttonText: null,
};
