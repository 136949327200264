import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { Checkbox } from '@powdr/components';
import { ButtonText } from '@powdr/constants';

export const EmailSignupForm = ({
  reCaptchaPublicKey,
  processForm,
  isUserVerified,
  setUserVerified,
  handleCaptchaExpiry,
  buttonText,
}) => {
  const captchaRef = useRef();

  return (
    <form
      method="post"
      action="https://www.pages03.net/powdr/WoodwardPAWebform/WoodwardPAEmailSignupForm2021"
      pageid="19530149"
      siteid="591638"
      parentpageid="19530147"
    >
      <div>
        <div className="row-item">
          <input
            className="form-control"
            type="text"
            name="First Name"
            id="control_COLUMN3"
            label="First Name"
            placeholder="First Name"
          />
          <input
            className="form-control"
            type="text"
            name="Last Name"
            id="control_COLUMN4"
            label="Last Name"
            placeholder="Last Name"
          />
          <input
            className="form-control"
            type="text"
            name="Email"
            id="control_EMAIL"
            label="Email Address"
            placeholder="ilovewoodward@woodward.com"
          />
        </div>

        <div className="row-item">
          <p>I'm interested in (pick as many as you like!):</p>
          <div className="flex-checkboxes">
            <div className="grouping">
              <Checkbox
                isIgnoreLabelFor
                className="formCheckbox"
                roundedToggle
                name="SUBSCRIPTION_GROUP"
                value="14050747"
                label="Skateboard"
              />
              <Checkbox
                isIgnoreLabelFor
                className="formCheckbox"
                roundedToggle
                name="SUBSCRIPTION_GROUP"
                value="14098516"
                label="BMX Race"
              />
              <Checkbox
                isIgnoreLabelFor
                className="formCheckbox"
                roundedToggle
                name="SUBSCRIPTION_GROUP"
                value="14050743"
                label="Scooter"
              />
            </div>
            <div className="grouping">
              <Checkbox
                isIgnoreLabelFor
                className="formCheckbox"
                roundedToggle
                name="SUBSCRIPTION_GROUP"
                value="14050742"
                label="Parkour"
              />
              <Checkbox
                isIgnoreLabelFor
                className="formCheckbox"
                roundedToggle
                name="SUBSCRIPTION_GROUP"
                value="14050734"
                label="BMX Freestyle"
              />
              <Checkbox
                isIgnoreLabelFor
                className="formCheckbox"
                roundedToggle
                name="SUBSCRIPTION_GROUP"
                value="15493697"
                label="Roller Skate"
              />
            </div>
          </div>
        </div>
      </div>
      <ReCAPTCHA
        ref={captchaRef}
        className="recaptcha"
        sitekey={reCaptchaPublicKey}
        theme="light"
        size="normal"
        onChange={() => setUserVerified(true)}
        onExpired={() => handleCaptchaExpiry()}
      />

      <button type="submit" value="Submit" disabled={!isUserVerified}>{buttonText || ButtonText.SUBMIT}</button>
    </form>
  );
};

EmailSignupForm.propTypes = {
  reCaptchaPublicKey: PropTypes.string.isRequired,
  processForm: PropTypes.func.isRequired,
  setUserVerified: PropTypes.func.isRequired,
  isUserVerified: PropTypes.bool.isRequired,
  handleCaptchaExpiry: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

EmailSignupForm.defaultProps = {
  buttonText: null,
};
